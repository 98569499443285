import axios from 'axios';

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
    async index(vuexContext, { currentPage = 1, perPage = 10 }) {
        const { data } = await axios.get(`/home-boxes/`, {
            params: {
                page: currentPage,
                perPage
            }
        });

        return data;
    },

    async store(vuexContext, homeBox) {
        const { data } = await axios.post(`/home-boxes`, homeBox);

        return data;
    },

    async update(vuexContext, homeBox) {
        const { data } = await axios.put(`/home-boxes/${homeBox.id}`, homeBox);

        return data;
    },

    async destroy(vuexContext, homeBox) {
        const { data } = await axios.delete(`/home-boxes/${homeBox.id}`);

        return data;
    }
};
