import axios from 'axios';

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
    async sendNotifications(vuexContext, { ids, tokens, message, sendToAll }) {
        const { data } = await axios.post(`/notifications/push-notifications`, {
            ids,
            tokens,
            message,
            sendToAll
        });

        return data;
    }
};
