<script>
import { authComputed } from '@state/helpers';

import MetisMenu from 'metismenujs/dist/metismenujs';

export default {
    components: {},

    data: () => ({
        contentPages: [
            {
                url: 'home',
                text: 'Home'
            },
            {
                url: 'terms-of-use',
                text: 'Terms Of Use'
            },
            {
                url: 'privacy-policy',
                text: 'Privacy Policy'
            },
            {
                url: 'product-information',
                text: 'Product Information'
            },
            {
                url: 'medical-information',
                text: 'Medical Information'
            }
        ]
    }),

    computed: {
        ...authComputed,
        isMobile() {
            return document.body.classList.value.includes('sidebar-enable');
        },
        currentUser() {
            return this.$store.getters['auth/currentUser'];
        },
        permissions() {
            return this.currentUser ? this.currentUser.acl.permissions : null;
        }
    },

    mounted: function () {
        // eslint-disable-next-line no-unused-vars
        var menuRef = new MetisMenu('#side-menu');
    },

    methods: {
        subIsActive(input) {
            const paths = Array.isArray(input) ? input : [input];
            return paths.some(path => {
                return this.$route.fullPath.startsWith(path); // current path starts with this path string
            });
        },
        containActiveContent(contentItems) {
            const activeIndex = contentItems.findIndex(item => {
                return '/contents/' + item.url === this.$route.path;
            });

            return activeIndex !== -1;
        }
    }
};
</script>

<template>
    <!--- Sidemenu -->
    <div id="sidebar-menu">
        <ul id="side-menu" class="metismenu">
            <li class="menu-title"> Navigation </li>
            <template v-if="currentUser && currentUser.role === 'admin'">
                <li>
                    <router-link to="/users-management">
                        <i class="fe-users" />
                        <span>Users</span>
                    </router-link>
                </li>

                <li>
                    <router-link to="/doctors-management">
                        <i class="fas fa-user-tag" />
                        <span>Doctors</span>
                    </router-link>
                </li>

                <li>
                    <router-link to="/contact">
                        <i class="fas fa-address-book" />
                        <span>Contact</span>
                    </router-link>
                </li>

                <li>
                    <router-link to="/push-notifications">
                        <i class="fe-flag" />
                        <span>Push notifications</span>
                    </router-link>
                </li>

                <li>
                    <router-link to="/mailing">
                        <i class="fas fa-mail-bulk" />
                        <span>Mailing</span>
                    </router-link>
                </li>

                <li
                    class="active"
                    :class="{ 'mm-active': subIsActive('/contents') }"
                >
                    <a
                        aria-expanded="true"
                        class="waves-effect"
                        :class="{ active: subIsActive('/contents') }"
                    >
                        <i class="fe-box" />
                        <span>Contents</span>
                    </a>
                    <ul
                        :class="{ in: subIsActive('/contents') }"
                        class="nav-second-level nav"
                        aria-expanded="true"
                    >
                        <li
                            v-for="item in contentPages"
                            :key="item.url"
                            :class="{
                                active: subIsActive('/contents/' + item.url)
                            }"
                        >
                            <router-link :to="'/contents/' + item.url">
                                {{ item.text }}
                            </router-link>
                        </li>
                    </ul>
                </li>
            </template>

            <li>
                <router-link to="/home-boxes">
                    <i class="fas fa-home" />
                    <span>Home Boxes</span>
                </router-link>
            </li>

            <li>
                <router-link to="/products">
                    <i class="fas fa-book-medical" />
                    <span>Products</span>
                </router-link>
            </li>

            <li>
                <router-link to="/medical-informations">
                    <i class="fas fa-newspaper" />
                    <span>Medical Information</span>
                </router-link>
            </li>

            <li>
                <router-link to="/labels">
                    <i class="fas fa-tag" />
                    <span>Labels</span>
                </router-link>
            </li>

            <li>
                <router-link to="/nps">
                    <i class="fa fa-star" />
                    <span>NPS</span>
                </router-link>
            </li>
        </ul>
        <div class="clearfix" />
    </div>
    <!-- End Sidebar -->
</template>

<style lang="scss">
@import '~metismenujs/scss/metismenujs';
</style>
