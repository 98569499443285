import store from '@state/store';

export default [
    {
        path: '/',
        name: 'dashboard',
        component: () => import('@views/dashboard/Dashboard'),
        meta: {
            authRequired: true
        },
        props: () => ({ user: store.state.auth.currentUser || {} })
    },

    {
        path: '/login',
        name: 'login',
        component: () => lazyLoadView(import('@views/auth/Login')),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'dashboard' });
                } else {
                    // Continue to the login page
                    next();
                }
            }
        }
    },

    {
        path: '/forget-password',
        name: 'forget-password',
        component: () => lazyLoadView(import('@views/auth/ForgetPassword')),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'dashboard' });
                } else {
                    // Continue to the login page
                    next();
                }
            }
        }
    },

    {
        path: '/reset-password/:token',
        name: 'reset-password',
        component: () => lazyLoadView(import('@views/auth/ResetPassword')),
        props: route => ({ token: route.params.token }),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                if (store.getters['auth/loggedIn']) {
                    next({ name: 'dashboard' });
                } else {
                    next();
                }
            }
        }
    },

    {
        path: '/logout',
        name: 'logout',
        meta: {
            authRequired: true,
            beforeResolve() {
                store.dispatch('auth/logout');
            }
        }
    },

    {
        path: '/users-management',
        name: 'usersManagement',
        component: () => import('@views/users-management/UsersManagement'),
        meta: {
            authRequired: true,
            role: 'admin'
        },
        props: () => ({ user: store.state.auth.currentUser || {} })
    },

    {
        path: '/doctors-management',
        name: 'doctorsManagement',
        component: () => import('@views/doctors-management/DoctorsManagement'),
        meta: {
            authRequired: true,
            role: 'admin'
        }
    },

    {
        path: '/doctors-management/:doctorId',
        name: 'doctorsEventsList',
        component: () => import('@views/doctors-management/DoctorEventsList'),
        meta: {
            authRequired: true,
            role: 'admin'
        }
    },

    {
        path: '/events/:eventId',
        name: 'event-users',
        component: () => import('@views/events/EventUsersList'),
        meta: {
            authRequired: true,
            permission: 'events'
        }
    },

    {
        path: '/products',
        name: 'products',
        component: () => import('@views/products/Products'),
        meta: {
            authRequired: true
        }
    },

    {
        path: '/contact',
        name: 'contact',
        component: () => import('@views/contact/ContactManagement'),
        meta: {
            authRequired: true,
            role: 'admin'
        }
    },

    {
        path: '/push-notifications',
        name: 'push-notifications',
        component: () => import('@views/push-notifications/PushNotifications'),
        meta: {
            authRequired: true,
            role: 'admin'
        }
    },

    {
        path: '/contents/:key',
        name: 'contents',
        component: () => import('@views/contents/Content'),
        meta: {
            authRequired: true,
            role: 'admin'
        }
    },

    {
        path: '/mailing',
        name: 'mailing',
        component: () => import('@views/mailing/MailingManagement'),
        meta: {
            authRequired: true,
            role: 'admin'
        }
    },

    {
        path: '/home-boxes',
        name: 'homeBoxes',
        component: () => import('@views/home-boxes/HomeBoxes'),
        meta: {
            authRequired: true,
            permission: 'homeBoxes'
        }
    },

    {
        path: '/nps',
        name: 'feedback',
        component: () => import('@views/feedback/Feedback'),
        meta: {
            authRequired: true,
            permission: 'feedback'
        }
    },

    {
        path: '/medical-informations',
        name: 'medicalInformations',
        component: () =>
            import('@views/medical-informations/MedicalInformations'),
        meta: {
            authRequired: true,
            permission: 'medicalInformations'
        }
    },

    {
        path: '/labels',
        name: 'labels',
        component: () => import('@views/labels/LabelsManagement'),
        meta: {
            authRequired: true,
            role: 'admin'
        }
    },

    {
        path: '/404',
        name: '404',
        component: require('@views/pages/_404').default,
        // Allows props to be passed to the 404 page through route
        // params, such as `resource` to define what wasn't found.
        props: true
    },
    // Redirect any unmatched routes to the 404 page. This may
    // require some server configuration to work in production:
    // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
    {
        path: '*',
        redirect: '404'
    }
];

// Lazy-loads view components, but with better UX. A loading view
// will be used if the component takes a while to load, falling
// back to a timeout view in case the page fails to load. You can
// use this component to lazy-load a route with:
//
// component: () => lazyLoadView(import('@views/my-view'))
//
// NOTE: Components loaded with this strategy DO NOT have access
// to in-component guards, such as beforeRouteEnter,
// beforeRouteUpdate, and beforeRouteLeave. You must either use
// route-level guards instead or lazy-load the component directly:
//
// component: () => import('@views/my-view')
//
function lazyLoadView(AsyncView) {
    const AsyncHandler = () => ({
        component: AsyncView,
        // A component to use while the component is loading.
        loading: require('@views/pages/_loading').default,
        // Delay before showing the loading component.
        // Default: 200 (milliseconds).
        delay: 400,
        // A fallback component in case the timeout is exceeded
        // when loading the component.
        error: require('@views/pages/_timeout').default,
        // Time before giving up trying to load the component.
        // Default: Infinity (milliseconds).
        timeout: 10000
    });

    return Promise.resolve({
        functional: true,
        render(h, { data, children }) {
            // Transparently pass any props or children
            // to the view component.
            return h(AsyncHandler, data, children);
        }
    });
}
